import Typography from "compass/data/Typography"
import { Trans, t } from "content"
import { F } from "msutils"
import LinkDONOTUSE from "compass-local/Link2"
import { Module } from "modules/routes"
import { useFormState } from "utils/form-input"
import { MSLayout } from "utils/layout"
import { Action } from "utils/actions"
import { EmailInput, PasswordInput } from "compass/input/TextInput"
import { BaseLayout } from "compass/baseLayout"
import { LoginUtils as Utils } from "./utils"

export default function Login() {
  const { state, useValidatedAction } = useFormState(Utils.schema)
  const logInAction = useValidatedAction(Utils.useLogIn(), { disableLog4XX: true })

  const forgotPasswordModule = Module(
    "/forgot-password",
    state.email.value ? { email: state.email.value } : undefined,
  )

  return (
    <MSLayout.InlineForm
      title={t("Sign in to your account")}
      errorMessage={logInAction.error?.message ?? null}
      submit={Action.mutation(t("Sign in"), { mutate: logInAction.mutateAsync })}
      footer={
        <Trans
          tKey="Don't have an account? <X>Sign up for free</X>"
          X={<LinkDONOTUSE href={Module("/sign-up").href} />}
        />
      }
    >
      <EmailInput
        enableAutocomplete
        autofocus
        {...F.props(state.email)}
        blur={() => setTimeout(state.email.blur, 200)}
      />
      <BaseLayout.VStack gap={1}>
        <PasswordInput enableAutocomplete {...F.props(state.password)} />
        <Typography variant="label" className="text-th-coolgrey-1">
          <Trans
            tKey="Forgot password? <X>Email me a link</X>"
            X={<LinkDONOTUSE href={forgotPasswordModule.href} />}
          />
        </Typography>
      </BaseLayout.VStack>
    </MSLayout.InlineForm>
  )
}
