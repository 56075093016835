import { ReactNode } from "react"
import Image from "next/image"
import Logo from "assets/logo.svg"

type Props = {
  children: ReactNode
}

export default function LoginBackground({ children }: Props) {
  return (
    <div className="grow min-h-[100vh] relative">
      <div className="absolute inset-0 overflow-y-auto flex grow justify-center">
        <div className="py-4 sm:py-16 vflex w-full sm:w-fit gap-4">
          <Image src={Logo} alt="Beam logo" className="mx-4" />
          <div className="bg-white w-full p-2 sm:p-4 sm:w-fit shadow-container sm:rounded-8">
            <div className="flex items-center justify-center">{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
