import Login from "modules/Login"
import LoginBackground from "components/misc/LoginBackground"

export default function Page() {
  return (
    <LoginBackground>
      <Login />
    </LoginBackground>
  )
}
