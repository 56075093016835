/* Auto-generated with `just module /login`. Do not edit directly. */
/* Updated 2024-01-16 14:08:09.377464 */

import { createContext, FC, ReactNode, useContext, useMemo } from "react"
import withInjectedQueriesDONOTUSE, { AwaitedQueryInjectedProps } from "utils/withInjectedQueries"
import { useQueries, useUrlParams } from "./config"

type Queryset = AwaitedQueryInjectedProps<typeof useQueries>
type ClientProps = Parameters<typeof useQueries> extends [infer P] ? P : {}
type Params = ReturnType<typeof useUrlParams>

const Ctx = createContext<
  | {
      queryset: Queryset
      isRefetching: boolean
      params: Params
    }
  | undefined
>(undefined)
type Props = ClientProps & {
  _queryset: Queryset
  _isRefetching: boolean
  children: ReactNode
}
const Provider = withInjectedQueriesDONOTUSE(
  useQueries,
  ({ _queryset, _isRefetching, children }: Props) => {
    const params = useUrlParams()
    const ctx = useMemo(
      () => ({
        queryset: _queryset,
        isRefetching: _isRefetching,
        params,
      }),
      [_queryset, _isRefetching, params],
    )

    return <Ctx.Provider value={ctx}>{children}</Ctx.Provider>
  },
)

export function useLoginContext() {
  const ctx = useContext(Ctx)
  if (ctx === undefined) throw new Error("Must use Login context within Login provider")
  return ctx
}

export function wrap<T extends object>(Component: FC<T>): FC<T & ClientProps> {
  return function Inner(props) {
    return (
      <Provider {...props}>
        <Component {...props} />
      </Provider>
    )
  }
}
