import { Cb } from "cb"
import { F } from "msutils"
import { auth } from "root/auth"

export namespace LoginUtils {
  export const schema = F.Group({
    spec: {
      email: F.Email({ required: true }),
      password: F.Text({ required: true }),
    },
  })

  export function useLogIn() {
    const { setAuth } = auth.usePreAuthContext()
    const createLogin = Cb.useCreateLoginHook()

    return async (valids: F.OutputShape<typeof schema>) => {
      const { session_token: token } = await createLogin({
        email: valids.email,
        password: valids.password,
      })
      setAuth(`Token ${token}`, "local")
    }
  }
}
