import { Q } from "cb"

export const moduleId = "Login"

export function useUrlParams() {
  return {}
}

export function useQueries() {
  return Q.group({})
}
