/* Auto-generated with `just module /login`. Do not edit directly. */
/* Updated 2024-01-16 14:08:09.377464 */

import Login from "./Component"
import { useUrlParams, moduleId } from "./config"
import { wrap } from "./context"

type UrlParams = ReturnType<typeof useUrlParams>
type ParamSpec = { [K in keyof UrlParams as UrlParams[K][2]]: UrlParams[K][0] }

declare global {
  export interface RouteDefinitionsV2 {
    [moduleId]: {
      template: "/login"
      params: ParamSpec
    }
  }
}

export default wrap(Login)
